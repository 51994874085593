<template>
  <v-row
    id="intro"
    class="wrapper overflow-hidden"
    align="center"
  >
    <v-col class="pl-8 pl-md-3">
      <v-card
        class="intro-content mx-auto transparent mb-12"
        flat
      >
        <v-img
          alt="HL2RP"
          class="mb-12 mx-auto"
          width="128"
          src="assets/img/logo.png"
        ></v-img>
        <h1 class="mb-6"><span class="yellowTitle">WN:</span> <span class="redTitle">Nuevo Orden</span></h1>
        <div class="d-none d-sm-block">
          <!-- Mute/unmute audio button -->
          <v-btn
            @click="muteAudio"
            class="muteButton"
            icon
          >
            <v-icon
              v-if="!muted"
              class="white--text"
            >mdi-volume-high</v-icon>
            <v-icon
              v-else
              class="white--text"
            >mdi-volume-off</v-icon>
          </v-btn>
          <v-btn
            @click="$vuetify.goTo('#arrival', options)"
            text
          >Recién llegados</v-btn>
          <v-btn
            @click="$vuetify.goTo('#gameplay', options)"
            text
          >Gameplay</v-btn>
          <v-btn
            @click="$vuetify.goTo('#lore', options)"
            text
          >Lore</v-btn>
          <v-btn
            @click="$vuetify.goTo('#team', options)"
            text
          >Equipo</v-btn>
        </div>
        <div class="mt-12 pt-6">
          <v-btn
            href="https://wno.es/forums/"
            class="body-1 py-5"
            tile
            outlined
            color="primary"
            x-large
          >
            Visita el foro<v-icon class="ml-1">mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-col>
    <div class="parallax"> 
      <audio
        class="bg-audio"
        autoplay
        loop
        muted
      >
        <source
          src="assets/audio/creation.ogg"
          type="audio/ogg"
        >
      </audio>
      <video
        class="bg-vid"
        autoplay
        loop
        muted
      >
        <source
          src="assets/videos/creation.mp4"
          type="video/mp4"
        >
      </video>
    </div>
  </v-row>
</template>
<script>
/* button to mute/unmute audio */
export default {
  name: 'LazyVideo',

  data () {
    return {
      isIntersecting: null,
      loaded: false,
      playing: false,
      posterLoaded: false,
      muted: false
    }
  },
  props: {
    poster: String,
    src: {
      type: String,
      required: false
    },
    sources: {
      type: Array,
      required: false
    },
    attrs: {
      type: Object,
      required: false,
      default: function () {
        return {
          controls: false,
          playsinline: true,
          loop: true,
          autoplay: true,
          muted: false
        }
      }
    },
    loadOffset: {
      default: '50%',
      required: false,
      type: String
    },
    pauseOnExit: {
      default: true,
      required: false,
      type: Boolean
    }
  },
  computed: {
    videoSources () {
      return this.sources || [this.src]
    }
  },
  methods: {
    getPoster () {
      if (this.isIntersecting && !this.posterLoaded) {
        this.posterLoaded = true
        return this.poster
      } else if (this.posterLoaded) {
        return this.poster
      } else {
        return ''
      }
    },
    muteAudio() {
      this.muted = !this.muted;
      var audio = document.getElementsByClassName("bg-audio");
      for (var i = 0; i < audio.length; i++) {
        audio[i].muted = this.muted;
      }
    },
    play () {
      this.playing = true
      this.$refs.video.play()
    },
    pause () {
      this.playing = false
      this.$refs.video.pause()
    },
    onIntersection ({ isIntersecting, intersectionRatio }) {
      this.isIntersecting = isIntersecting
      if (isIntersecting && intersectionRatio > 0) {
        this.play()
      } else {
        this.pause()
      }
    }
  },
  watch: {
    loaded (isLoaded) {
      if (isLoaded) {
        this.$emit('loaded')
      }
    },
    isIntersecting (isIntersecting, wasIntersecting) {
      // when out of view pause video
      if (wasIntersecting && this.playing && this.pauseOnExit) {
        this.$refs.video.pause()
      }
      // load if video is intersecting
      if (isIntersecting && !this.loaded) {
        this.$refs.video.load()
      }
    }
  }
}
</script>
<style lang="scss">
#intro {
  .intro-content {
    text-align: center;
    z-index: 1;
    h1 {
      font-size: 5rem;
      font-family: "Source Sans Pro", sans-serif;
      text-transform: uppercase;
      letter-spacing: 10px;
      color: white;
      line-height: 1;
    }
    .v-btn {
      font-family: "Source Sans Pro", sans-serif;
      text-transform: uppercase;
      font-size: 1.4rem;
      letter-spacing: 6px;
      color: rgb(196, 196, 196);
      &:hover {
        color: white;
      }
    }
  }
  
  .parallax {
    .bg-vid {
      background-color: rgba(34, 32, 64);
      width: 100%;
    }
    position: fixed;
    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.5);
      z-index: 3;
    }
    /*
  .parallax {
    background-image: url("https://i.imgur.com/70YbGGe.png");
    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.7);
    }*/
  }
}
@media (max-width: 450px) {
  #intro {
    .intro-content {
      h1 {
        font-size: 2.8rem;
      }
    }
  }
}

@media (max-width: 1600px) {
  #intro {
    .parallax {
      transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      .bg-vid {
        width: unset !important;
        max-width: unset !important;
        min-height: 100vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.mb-12 .mx-auto:hover{
  transform: scale(1.1);
}

.mb-12 .mx-auto{
  transition: 0.3s ease-in-out;
}

.yellowTitle{
  color: #FFCC00;
}

.redTitle{
  color: var(--v-primary-base);
}


</style>
