<template>
  <v-row
    id="gameplay"
    class="wrapper overflow-hidden"
    align="center"
  >
    <v-col
      class="pl-8 pl-md-3"
      cols="12"
      md="5"
      lg="3"
      offset-md="1"
      offset-lg="2"
    >
      <v-card
        max-width="360"
        class="content mx-auto transparent"
        flat
      >
        <h1>Gameplay</h1>
        <h2>Sobre HL2RP.</h2>
        <p>Descubre el nuevo servidor de Half-Life 2 Roleplay (HL2RP), con un sistema de combate, 
          una jugabilidad y una interfaz de usuario renovados, que conforman una experiencia única. 
          Inspirándonos en juegos como Divinity Original Sin y Xcom 2, hemos creado un nuevo género HL2RPG.
        </p>
        <p>Nuestro exclusivo sistema de habilidades ofrece una nueva visión de la progresión del jugador, 
          diferente a todo lo visto hasta ahora. La libertad es la clave, y te permitimos jugar con 
          el personaje que realmente quieres ser. Disfruta de nuestra historia única, divertidas características 
          e interesantes mecánicas, ¡y da vida a tu personaje en nuestro HL2RP!
        </p>
        <v-btn
          href="https://wno.es/forums/threads/190/"
          class="mt-4"
          outlined
        >
          <v-icon
            class="mr-2"
            small
          >mdi-calendar</v-icon>Guía de juego
        </v-btn>
      </v-card>
    </v-col>
    <div class="parallax">
        <video
          class="bg-gam"
          autoplay
          loop
          muted
        >
          <source
            src="assets/videos/bgvid.mp4"
            type="video/mp4"
          >
        </video>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'LazyVideo',

  data () {
    return {
      isIntersecting: null,
      loaded: false,
      playing: false,
      posterLoaded: false,
      muted: false
    }
  },
  props: {
    poster: String,
    src: {
      type: String,
      required: false
    },
    sources: {
      type: Array,
      required: false
    },
    attrs: {
      type: Object,
      required: false,
      default: function () {
        return {
          controls: false,
          playsinline: true,
          loop: true,
          autoplay: true,
          muted: false
        }
      }
    },
    loadOffset: {
      default: '50%',
      required: false,
      type: String
    },
    pauseOnExit: {
      default: true,
      required: false,
      type: Boolean
    }
  },
  computed: {
    videoSources () {
      return this.sources || [this.src]
    }
  },
  methods: {
    getPoster () {
      if (this.isIntersecting && !this.posterLoaded) {
        this.posterLoaded = true
        return this.poster
      } else if (this.posterLoaded) {
        return this.poster
      } else {
        return ''
      }
    }
  },
  watch: {
    loaded (isLoaded) {
      if (isLoaded) {
        this.$emit('loaded')
      }
    },
    isIntersecting (isIntersecting, wasIntersecting) {
      // when out of view pause video
      if (wasIntersecting && this.playing && this.pauseOnExit) {
        this.$refs.video.pause()
      }
      // load if video is intersecting
      if (isIntersecting && !this.loaded) {
        this.$refs.video.load()
      }
    }
  }
}
</script>
<style lang="scss">
#gameplay {
  .parallax {
      .bg-gam {
        width: 100% !important;
        max-width: unset !important;
        min-height: 100vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 4;
      }
  }
}

@media (max-width: 1800px) {
  #gameplay {
    .parallax {
      background-image: url("/public/assets/img/backgrounds/destiny.png");
      &:before {
        content: "";
        background-color: rgba(34, 32, 64, 0.7);
      }
    }
    .parallax .bg-gam {
      display: none;
    }
  }
}

</style>