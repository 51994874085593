<template>
  <v-row
    id="team"
    class="wrapper overflow-hidden"
    align="center"
  >
    <v-col
      class="pl-8 pl-md-3"
      cols="12"
      md="5"
      lg="3"
      offset-md="1"
      offset-lg="2"
    >
      <v-card
        class="content transparent"
        flat
      >
        <h1>El Equipo</h1>
        <h2 class="font-italic">Quienes lo hacen posible</h2>
        <div class="d-block d-md-flex">
          <v-list
            color="rgba(0,0,0,0.4)"
            class="mr-md-12"
          >
            <template v-for="(item, index) in management">
              <v-subheader
                v-if="item.header"
                :key="item.header"
                v-text="item.header"
              ></v-subheader>

              <v-divider
                v-else-if="item.divider"
                :key="index"
                :inset="item.inset"
              ></v-divider>

              <v-list-item
                v-else
                :key="item.name"
              >
                <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="d-flex align-center justify-space-between">{{item.name}}
                    <v-chip
                      class="ml-8"
                      outlined
                      x-small
                    >{{item.title}}</v-chip>
                  </v-list-item-title>

                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <v-list color="rgba(0,0,0,0.4)">
            <template v-for="(item, index) in devs">
              <v-subheader
                v-if="item.header"
                :key="item.header"
                v-text="item.header"
              ></v-subheader>

              <v-divider
                v-else-if="item.divider"
                :key="index"
                :inset="item.inset"
              ></v-divider>

              <v-list-item
                v-else
                :key="item.name"
              >
                <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="d-flex align-center justify-space-between">{{item.name}}
                    <v-chip
                      class="ml-8"
                      outlined
                      x-small
                    >{{item.title}}</v-chip>
                  </v-list-item-title>

                </v-list-item-content>
                
              </v-list-item>
            </template>
          </v-list>
        </div>
      </v-card>
    </v-col>
    <div class="parallax"></div>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    management: [
      {
        avatar: 'assets/img/avatars/joel.jpg',
        name: 'Joel',
        title: 'Director Comunitario de WNO'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/ivane.jpg',
        name: 'Ivane',
        title: 'Administración Comunitaria'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/boo.jpg',
        name: 'Boo',
        title: 'Administración Comunitaria'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/primo.jpg',
        name: 'Que Pasa Primo',
        title: 'Equipo Administrativo'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/adrian.jpg',
        name: 'Adrián',
        title: 'Equipo Administrativo'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/josep.jpg',
        name: 'Josep',
        title: 'Equipo Administrativo'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/miclox.gif',
        name: 'Miclox',
        title: 'Equipo Administrativo'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/spyrot.jpg',
        name: 'Spyrot',
        title: 'Equipo Administrativo'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/sopero.png',
        name: 'Sopero',
        title: 'Equipo Administrativo'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/trsh.png',
        name: 'Trsh',
        title: 'Equipo Administrativo'
      }
    ],
    devs: [
      {
        avatar: 'assets/img/avatars/atle.png',
        name: 'Atle',
        title: 'Director de Willard Networks'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/votton.jpg',
        name: 'Votton',
        title: 'Jefe de Desarrollo de Willard Networks'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/whitehole.jpg',
        name: 'Whitehole',
        title: 'Desarrollador Comunitario y Mapper'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/miliviu.jpg',
        name: 'Miliviu',
        title: 'Desarrollador Comunitario'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/usuariozombie.gif',
        name: 'Usuariozombie',
        title: 'Desarrollador Comunitario'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/geferon.jpg',
        name: 'Geferon',
        title: 'Desarrollador'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/logo.png',
        name: 'Comunidad de WNO',
        title: 'Equipo creativo y... ¡Tú!'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/pixel.gif',
        name: 'Pixel',
        title: '¡Gracias por empezarlo todo!'
      }
    ]
  })
}
</script>
<style lang="scss">
#team {
  .parallax {
    background-image: url("/public/assets/img/backgrounds/team.png");
    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.85);
    }
  }
}
</style>
