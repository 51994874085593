<template>
  <v-row
    id="lore"
    class="wrapper overflow-hidden"
    align="center"
  >
    <v-col
      class="pl-8 pl-md-3"
      cols="12"
      md="5"
      lg="3"
      offset-md="1"
      offset-lg="2"
    >
      <v-card
        max-width="360"
        class="content mx-auto transparent"
        flat
      >
        <h1>Lore</h1>
        <h2 class="font-italic">Disfruta de un Lore único.</h2>
        <p>En el año 2001, se produjo la Cascada de Resonancia, que fusionó Xen y la Tierra en una terrible 
          catástrofe extradimensional que mató a cientos de millones de personas mientras la humanidad se 
          desmoronaba. Pasaron los años, y mientras la humanidad se marchitaba lentamente bajo la amenaza de Xen, 
          la Alianza invadió la Tierra, estableciendo su dominio frío y despiadado.
        </p>
        <p>
          Ha pasado más de una década desde la Guerra. En el apogeo de su dominio, la voluntad de la 
          Alianza está por encima de todo. Incluso ante tal poder, la llama de la resistencia arde en 
          los corazones de humanos y vortigaunts. El futuro será moldeado por los valientes y ambiciosos, 
          ya sea la libertad, la subyugación o la extinción.
        </p>
        <v-btn
          href="https://wno.es/forums/threads/71/"
          class="mt-4"
          outlined
        >
          <v-icon
            class="mr-2"
            small
          >mdi-calendar</v-icon>Leer más
        </v-btn>
        <a
          href="https://www.deviantart.com/jqueary1991"
          target="_blank"
          class="font-italic text-decoration-none d-block mt-3"
          style="color: #FFCC00;"
        >Background artwork by jQueary1991</a>
      </v-card>
    </v-col>
    <div class="parallax"></div>
  </v-row>
</template>

<script>
export default {
}
</script>
<style lang="scss">
#lore {
  .parallax {
    background-image: url("/public/assets/img/backgrounds/artwork.jpg");
    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.7);
    }
  }
}
</style>