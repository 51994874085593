<template>
  <v-row
    id="arrival"
    class="wrapper overflow-hidden"
    align="center"
  >
    <v-col
      class="pl-8 pl-md-3"
      cols="12"
      md="5"
      lg="3"
      offset-md="1"
      offset-lg="2"
    >
      <v-card
        max-width="370"
        min-width="360"
        class="content mx-auto transparent"
        flat
      >
        <h1>Recién llegados</h1>
        <h2>Bienvenido a C<span class="heading"><span id="text"></span><span class="blinking-cursor">|</span></span></h2>
        <p>El mundo está bajo la ocupación de una fuerza alienígena apodada la Alianza. Has llegado a tu nueva ciudad y debes encontrar la manera de sobrevivir en el <span class="redText">Nuevo Orden</span>.</p>
        <p>¿Decidirás seguir el sistema? ¿O elegirás cuidadosamente ir contra la corriente? Únete a la lucha de la humanidad contra la opresión y la extinción o únete al gran colectivo. Tú eres el narrador ¿qué historia contarás?</p>
        <v-btn
          href="https://discord.gg/shzmdHF2fg"
          target="_blank"
          class="mt-4 mr-3"
          outlined
        >
        <v-icon
          class="mr-2"
          small
        >mdi-microphone-message</v-icon>Discord
        </v-btn>
        <v-btn
          href="https://wno.es/forums/threads/461/"
          class="mt-4"
          outlined
        >
          <v-icon
            class="mr-2"
            small
          >mdi-forum</v-icon>Foro
        </v-btn>
        <a
          href="https://www.artstation.com/thereeno"
          target="_blank"
          class="font-italic text-decoration-none d-block mt-3"
          style="color: #FFCC00;"
        >Background artwork by Jason Chaudoin</a>
      </v-card>
    </v-col>
    <div class="parallax"></div>
  </v-row>
</template>

<script src="./main.js"></script>

<script>

export default {

  data () {
    return {
      timerCount: 5
    }
  },

  watch: {
    timerCount: {
      handler () {
        setTimeout(() => {
          var random = Math.random() * (50 - 1) + 1
          this.timerCount = Math.floor(random)
        }, 2000)
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }

  }
}

</script>
<style lang="scss">
#arrival {
  .parallax {
    background-image: url("/public/assets/img/backgrounds/experience.png");
    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.7);
    }
  }
}
.blinking-cursor {
  animation: blink 1s infinite;
  color: #FFCC00;
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
#text {
  color: #FFCC00;
}
.redText {
  color: var(--v-primary-base);
}
</style>
